import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/app.constansts';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import swal from 'src/assets/styles/vendors/sweetalert2/dist/sweetalert2.js';
import 'src/assets/styles/vendors/sweetalert2/src/sweetalert2.scss'
@Injectable({
  providedIn: 'root'
})
export class DescargarArchivoService {

  _baseUrl: string;

  constructor(private http: HttpClient) {
    this._baseUrl = AppConstants.baseURL;
   }

   obtenerArchivo(tipo : string, nombreArchivo : string, tipoArchivo : string){
    return this.http.get(this._baseUrl + '/api/UploadFiles/getArchivo/'+ tipo +'/'+ nombreArchivo, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
        responseType: 'blob'
      }).pipe(map((resp: any) => {
      return resp;
    })
    ).subscribe(resp =>{
      return this.downLoadFile(resp, tipoArchivo, nombreArchivo);
    });
   }
   downLoadFile(data: any, type: string, nombreArchivo : string) {
    var blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = nombreArchivo;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
  }
  obtenerExtension(nombreArchivo : string){
    var nombreCortado = nombreArchivo.split('.');
    return nombreCortado[nombreCortado.length - 1];
  }
  obtenerTipoArchivo(){

  }
}
