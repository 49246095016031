import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ValorservicioService {
  
  _baseUrl: string;
  token : string;

  constructor(private http: HttpClient, private loginService : LoginService) {
    this._baseUrl = AppConstants.baseURL;
    this.token = loginService.token;
  }

  crearValorServicio(form, ids){  
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/peso/PostCrearPeso',{
      "idServicio": ids.idServicio,
      "idMandante": ids.idMandante,
      "valor": form.valor,
      "fechaValida": form.fechaValida,
      "comentarios": form.comentarios
    },{headers});
  }
  getValorServicioById(ids){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/peso/PostObtienePeso',{
      "idServicio": ids.idServicio,
      "idMandante": ids.idMandante,
    }, {headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

  getHistorial(ids){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/peso/PostObtienePesoHistoria',{
      "idServicio": ids.idServicio,
      "idMandante": ids.idMandante,
    },{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

}
