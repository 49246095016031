import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import { ModalService } from '../services/modal/modal.service';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor( private router : Router, private modalServ: ModalService) { }

  ngOnInit() {
    
  }

}
