import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalInterface } from './modal.interface';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	onNoticeChanged$: BehaviorSubject<ModalInterface>;

	constructor() {
		this.onNoticeChanged$ = new BehaviorSubject(null);
	}

	setNotice(message?: string, type?: string, title?: string) {
		console.log(message, type, title);
		const notice: ModalInterface = {
			message: message,
			type: type,
			title: title
		};
		this.onNoticeChanged$.next(notice);
	}
}

