import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {
  constructor(public loginServices : LoginService){}

  canActivate(){
    if(this.loginServices.estaLogeado()){
      return true;
    }else{
      return false;
    }
  }
}
