import { AppComponent } from './../../../app.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { Usuario } from 'src/app/models/negocio/usuario.model';
import { Router } from '@angular/router';

import { AppConstants } from '../../../app.constansts';

@Injectable({
  providedIn: 'root',
  })
export class LoginService {
  usuario : Usuario;
  token : string;
  _baseUrl: string;
  constructor(private http: HttpClient,private router: Router) { 
    this.cargarStorage();
    this._baseUrl = AppConstants.baseURL;
  }
  cargarStorage(){
    if(localStorage.getItem('securityInfo')){//esto debería ser con el token
      this.token = localStorage.getItem('securityInfo');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
    }else{
      this.logOut();
    }
  }
  estaLogeado(){
    return (this.token.length > 5) ? true : false; //(this.token.length > 5) ? true : false;
  }
  getUserDetail(){
    return this.http.get(this._baseUrl + '/api/auth/usuarios'
    ).subscribe(data => {console.log(data)});
  }
  autenticacion(req){ 
    return this.http.post(this._baseUrl + '/api/login',{
      "NombreUsuario": req.rut,
      "contrasena": req.password
    }).pipe(map((resp : any)=>{
      localStorage.clear();
      this.usuario = resp["data"];
      //localStorage.setItem('id', resp["data"].id);
      localStorage.setItem('securityInfo', resp["securityInfo"]); //falta token 
      localStorage.setItem('usuario', JSON.stringify(resp["data"]));
      // this.oportunidadService.usuario = resp["data"];
      // this.oportunidadService.token = resp["securityInfo"];
      this.token = resp["securityInfo"];
      return true;
    })
    );
  }
  logOut(){
    this.usuario = null;
    this.token = '';
    localStorage.removeItem('securityInfo');
    //localStorage.removeItem('id');
    localStorage.removeItem('usuario');
    this.router.navigate(['/login']);
  }
}
