import { Component, OnInit } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoginService, OportunidadService } from '../services/services.index';
import { Login } from '../models/login/login.model';
import { Usuario } from 'src/app/models/negocio/usuario.model';
import swal from 'src/assets/styles/vendors/sweetalert2/dist/sweetalert2.js';
import 'src/assets/styles/vendors/sweetalert2/src/sweetalert2.scss'
import { RutValidator } from 'ng-rut-validator';
import { HttpErrorResponse } from '@angular/common/http';

declare var $: any; //se utiliza para jquery

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  usuario : any = Usuario;
  login : any = Login;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private LoginServices: LoginService,
    private oportunidadServices : OportunidadService
  ) {
    this.loginForm = this.formBuilder.group({
      rut: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(8)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
    });

  }

  ngOnInit() {

  }

  get f() { return this.loginForm.controls; }

  onSubmit(form) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    swal({title: 'Cargando...', allowOutsideClick: false, showConfirmButton: false });
    swal.showLoading();
    var passwordValue = this.md(form.value.password);
    this.login = new Login(form.value.rut, passwordValue);
    this.LoginServices.autenticacion(this.login).subscribe((resp: any) => {
      if (resp == true) {
        this.oportunidadServices.usuario = this.LoginServices.usuario;
        this.oportunidadServices.token = this.LoginServices.token;
        this.router.navigate(['op-lista']).then(()=>{
          swal.close();
        });
      } else {
        swal.close();
        swal('Error', 'El Rut o la Contraseña son inválidos.', 'error');
      }
    },
    (error:HttpErrorResponse) =>{
      swal.close();
      swal('Error', error.error["control"].mensajeControl.toString(), 'error');
    }
    );
  }
  // ForzarCerrarModal() {
  //   if ($('.modal-backdrop').is(':visible')) {
  //     $('body').removeClass('modal-open');
  //     $('.modal-backdrop').remove();
  //   };
  // }

  //método para encriptar
  md(password) {
    //encriptado de password
    var passEncrypt = Md5.hashStr(password);
    //numeros random
    var numerosRandom = this.rand_code();
    var numerosRandom2 = this.rand_code();
    // //encriptacion de numeros random
    // numerosRandom = Md5.hashStr(numerosRandom);
    // numerosRandom2 = Md5.hashStr(numerosRandom2);
    //encriptacion final
    var encriptacion = numerosRandom + '^' + password + '^' + numerosRandom2;
    return encriptacion;
  }
  rand_code() {
    var caracteres = "0123456789abcdefghijklmnopqrstvwyz";
    var code = "";
    for (var x = 0; x < 10; x++) {
      var rand = Math.floor(Math.random() * caracteres.length);
      code += caracteres.substr(rand, 1);
    }
    return code;
  }
  // generarNumerosRandom() {
  //   var result;
  //   for (var _i = 0; _i < 10; _i++) {
  //     var item = Math.round(Math.random() * (9 - 0) + 0);
  //     result = (result != null && result != '' && result != undefined) ? result + item.toString() : item.toString();
  //   }
  //   return result;
  // }
}
