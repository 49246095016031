
import { MandanteUsuario } from './mandanteUsuario.model';
import { Rol } from './rol.model';
export class Usuario{
    constructor(
        public _id:string,
        public id:string,
        public rut:string,
        public nombreUsuario:string,
        public nombre:string,
        public apellidos:string,
        public contrasena:string,
        public fechaCreacion:Date,
        public estado:string,
        public numeroEmpresa:string,
        public rol:string,
        public urlFoto : string,
        public listaMandante : MandanteUsuario[]
        ){}
}