import { Component, OnInit, Output, Input } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { ModalInterface } from '../../services/modal/modal.interface';
import { Router } from '@angular/router';
declare var $: any;//se utiliza para jquery

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
	@Output() type: any;
	@Output() message: any = '';
	@Output() title: any = '';
	@Input() ruta: string;
	constructor(public authNoticeService: ModalService, private router: Router) { }

	ngOnInit() {
		this.authNoticeService.onNoticeChanged$.subscribe(
			(notice: ModalInterface) => {
				this.message = notice.message;
				this.type = notice.type;
				this.title = notice.title;
			}
		);
	}
	btnAceptarModal() {
		$('#modalConfirmacion').modal("hide");
		if (this.ruta != undefined) {
			this.router.navigate([this.ruta.toString()]);
		}

	}
}

