import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ContratoService {

  _baseUrl: string;

  constructor(private http: HttpClient, private loginService : LoginService) {
    this._baseUrl = AppConstants.baseURL;
   }

  getContratoByIdOportunidad(idOportunidad) {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/contrato/getByIdOportunidad/'+ idOportunidad,{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
  guardarDatosContrato(datosContrato, idMandante, clienteContrato, idOportunidad, nombreArchivo){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/contrato/PostDatosContrato', {
      "idOportunidad": idOportunidad,
      "datosDelContrato": {
        "idServicio": datosContrato.idServicio,
        "idMandante": idMandante,
        "idPlan": datosContrato.idPlan,
        "comentariosContrato": datosContrato.comentariosContrato,
        "clienteContrato": {
          "rut": clienteContrato.rut,
          "nombre": clienteContrato.nombre,
          "apellidos": clienteContrato.apellidos,
          "telefonoPrimario": clienteContrato.telefonoPrimario,
          "telefonoSecundario": clienteContrato.telefonoSecundario,
          "correoElectronico": clienteContrato.correoElectronico,
          "sexoCliente": clienteContrato.sexoCliente
        },
        "facturaElectronica": datosContrato.facturaElectronica,
        "urlAudio": nombreArchivo
      }
    },{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
  guardarInfoContrato(form, fb, idOportunidad){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/contrato/PostInfoContrato/',{
      "idOportunidad": idOportunidad,
      "infoMandanteContrato": {
        "numeroServicio": fb.numeroServicio,
        "informacionContratoMandante": fb.informacionContratoMandante,
        "fechaAgendaInstalacion": form.fechaAgendaInstalacion,
        "fechaInstalacion": form.fechaInstalacion,
        "estadoInstalacion": form.estadoInstalacion,
        "observacionesInstalacion": fb.observacionesInstalacion
      }
    },{headers});
  }
  getTiposDocumentos(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/TipoDocumento',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
  guardarAuditoriaContrato(idOportunidad, estadoAuditoria, listaDocumentos){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/contrato/PostInfoAuditoria',{
      "idOportunidad": idOportunidad,
      "auditoriaContrato": {
        "estadoAuditoria": estadoAuditoria,
        "listaDocumento": listaDocumentos
      }
    },{headers});
  }
}
