import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/auth/login/login.service';

@Component({
  selector: 'app-nopagefound',
  templateUrl: './nopagefound.component.html',
  styleUrls: ['./nopagefound.component.scss']
})
export class NopagefoundComponent implements OnInit {

  constructor(public loginService : LoginService) { }

  ngOnInit() {
  }

}
