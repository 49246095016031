import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpEventType
} from "@angular/common/http";
import { AppConstants } from "../../app.constansts";
import { LoginService } from "../auth/login/login.service";
import swal from "src/assets/styles/vendors/sweetalert2/dist/sweetalert2.js";
import "src/assets/styles/vendors/sweetalert2/src/sweetalert2.scss";

@Injectable({
  providedIn: "root"
})
export class AuditoriaService {
  _baseUrl: string;
  progress: number;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this._baseUrl = AppConstants.baseURL;
  }

  downloadFile(url: string) {
    const uri = this._baseUrl + url;
    const pwa = window.open(uri);
    if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
      alert("Por favor, desactica tu bloqueo de Pop-up e intenta de nuevo.");
    }
  }

  subirActualizacion(file: File) {
    const formData = new FormData();
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + this.loginService.token
    );

    formData.append(file.name, file);
    const uploadReq = new HttpRequest(
      "POST",
      this._baseUrl + "/api/auditoria/actualizarAuditoria",
      formData,
      {
        reportProgress: false,
        headers
      }
    );

    this.http.request(uploadReq).subscribe(
      resp => {
        if (resp.type == HttpEventType.Response) {
          this.downloadFile(resp.body["data"]);
        }
      },
      mensaje => {
        if (mensaje.status == 400) {
          swal(
            "Error",
            mensaje.error["control"].mensajeControl.toString(),
            "error"
          );
        }

        if (mensaje.error.data) {
          this.downloadFile(mensaje.error.data);
        }
      }
    );
  }
}
