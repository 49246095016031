import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from 'src/app/models/negocio/usuario.model';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../app.constansts';
import { Router } from '@angular/router';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class OportunidadService {

  usuario : Usuario;
  token : string; 
  _baseUrl: string;

  constructor(private http : HttpClient, private router: Router, private loginService : LoginService) { 
    this._baseUrl = AppConstants.baseURL;
    this.token = this.loginService.token;
    this.usuario = this.loginService.usuario;
  }

  getOportunidadById(id){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/oportunidad/getOportunidadById/'+ id,{headers})
  }

  obtenerOportunidadByIdMandante(pagina, numRegistro, filtros, listaMandante, tipoFiltro){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/Oportunidad/postFiltraOportunidades',{ 
      "NumeroOportunidad": filtros.nOportunidad,
      "listaMandante": listaMandante,
      "pagina": pagina,
      "numeroRegistros" : numRegistro,
      "rol" : this.loginService.usuario.rol,
      "idServicio" : filtros.idServicio,
      "fechaDesde" : filtros.fechaDesde,
      "fechaHasta" : filtros.fechaHasta,
      "listaEstadosOportunidades": filtros.listaEstadosOportunidades,
      "tipoFiltro": tipoFiltro
    },{headers});
  }

  AsignarMandante(idOportunidad , idMandante ){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl +'/api/oportunidad/PostAsignaUnMandane',{ 
      "id": idOportunidad,
      "idMandante": idMandante
    },{headers});
  }

  getMotivoLlamado() {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl +'/api/motivos/getOpcionesLlamadaCliente',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

  getMotivoCancelacion(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl +'/api/motivos/getMotivosCancelacion',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

  getHistorial(idOportunidad, pagina, numeroRegistros){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl +'/api/oportunidad/PostHistoriaOptById', {
      "id": idOportunidad,
      "pagina": pagina,
      "numeroRegistros": numeroRegistros
    },{headers});
  }

  cancelarOportunidad(form, idOportunidad){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl +'/api/oportunidad/PostCancelar', {
      "idOportunidad": idOportunidad,
      "idMotivo": form.idMotivo,
      "comentario": form.comentario
    },{headers});
  }
  cerrarOportunidad(idOportunidad :string){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl +'/api/Oportunidad/PostCierreExistoso', {
      "id": idOportunidad
    },{headers});
  }

  LlamadaAgente(idMotivo, idOportunidad, comentario){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/oportunidad/PostMotivoLlamada', {
      "idOportunidad": idOportunidad,
      "idMotivo": idMotivo,
      "comentario": comentario 
    },{headers});
  }

}
