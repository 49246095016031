import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class MensajeService {
  
  _baseUrl: string;

  constructor(private http : HttpClient, private loginService : LoginService) { 
    this._baseUrl = AppConstants.baseURL;
  }

  getAllMensajesAgente(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/mensaje/getMensajesEnviar',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
  EnviarMensajesAgente(idAgente, idOportunidad, idMensaje){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/mensaje/PostMensajes', {
      "idAgente": idAgente,
      "idOportunidad": idOportunidad,
      "idMensaje": idMensaje,
      "tipoMensaje": "Q" 
    },{headers});
  }
}
