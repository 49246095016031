import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

//COMPONENTES
import { ModalComponent } from '../utils/modal/modal.component';
import { ModalCargandoComponent } from '../utils/modal-cargando/modal-cargando.component';


@NgModule({
  declarations: [
    ModalCargandoComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
      ModalCargandoComponent,
      ModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UtilsModule { }
