import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../../models/negocio/usuario.model';
import { map } from 'rxjs/operators';

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  _baseUrl: string;
  token : string;

  constructor(private http: HttpClient, private loginService : LoginService) { 
    this._baseUrl = AppConstants.baseURL;

    if (loginService.estaLogeado()){
      this.token = loginService.token;
    }else{
      loginService.logOut();
    }
  }
  getAllUsuarios(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Usuario', {headers});
  }
  getAllUsuariosPaginado(pagina, numRegistro){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/usuario/PostObtenerTodos',{
      "estado": "Activo",
      "pagina": pagina,
      "numeroRegistros" : numRegistro
    }, {headers});
  }
  getUserById(id) {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get<Usuario>(this._baseUrl + '/api/Usuario/' + id,{headers});//this.baseUrl + '/' + id
  }
  crearUsuario(req,nuevacontrasena){  
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/Usuario',{
      "nombreUsuario": req.nombreUsuario,
      "contrasena": nuevacontrasena,
      "numeroEmpresa": req.numeroEmpresa,
      "rut": req.rut,
      "nombre": req.nombre,
      "apellidos": req.apellidos,
      "rol": req.rol,
      "urlFoto": req.urlFoto,
      "listaMandante": req.listaMandante
    }, {headers});
  }
  updateUsuario(req, nuevacontrasena){
    console.log(this.token);
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.put(this._baseUrl + '/api/Usuario', {
      "_id": req._id,
      "id": req.id,
      "nombreUsuario": req.nombreUsuario,
      "contrasena": nuevacontrasena,
      "numeroEmpresa": req.numeroEmpresa,
      "rut": req.rut,
      "nombre": req.nombre,
      "apellidos": req.apellidos,
      "rol": req.rol,
      "estado": req.estado,
      "urlFoto": req.urlFoto,
      "listaMandante": req.listaMandante
    },{headers})
  }
  getAllRoles(){
    return this.http.get(this._baseUrl + '/api/Roles').pipe(map((resp: any) => {
      return resp;
    })
    );
  }
}
