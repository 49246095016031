import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConstants } from '../../app.constansts';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  _baseUrl: string;
  
  constructor(private http : HttpClient) { 
    this._baseUrl = AppConstants.baseURL;
  }

  getAllRegion(){
    return this.http.post(this._baseUrl + '/api/divisionPolitica/PostObtieneRegiones',{ 
      "estado": "Activo",
      "pagina": "0",
      "numeroRegistros": "0"
    });
  }
  getProvinciaByIdRegion(numeroRegion){
    return this.http.post(this._baseUrl + '/api/divisionPolitica/PostObtieneProvinciasByIdRegion',{ 
      "id": numeroRegion,
      "estado": "Activo",
      "pagina": "0",
      "numeroRegistros": "0"
    });
  }
  getComunasByIdProvincia(numeroProvincia){
    return this.http.post(this._baseUrl + '/api/divisionPolitica/PostObtieneComunasByIdProvincia',{ 
      "id": numeroProvincia,
      "estado": "Activo",
      "pagina": "0",
      "numeroRegistros": "0"
    });
  }
}
