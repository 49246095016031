import { Injectable } from '@angular/core';
import { Mandante } from '../../models/negocio/mandante.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class MandanteService {

  _baseUrl: string;
  token : string

  constructor(private http: HttpClient, private loginService : LoginService) { 
    this._baseUrl = AppConstants.baseURL;
    this.token = loginService.token;
  }

  getAllMandante(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Mandante',{headers});
  }
  crearMandante(req){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/Mandante',{
      "RutMandante": req.rutMandante,
      "Mandante": req.mandante,
      "prefijo": req.prefijo,
      "urlLogo": req.urlLogo
    },{headers});
  }
  getByIdMandante(id){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Mandante/'+id,{headers});
  }
  updateMandante(req){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.put(this._baseUrl + '/api/Mandante',{
      "_id": req._id,
      "id": req.id,
      "rutMandante": req.rutMandante,
      "mandante": req.mandante,
      "prefijo": req.prefijo,
      "urlLogo": req.urlLogo,
      "estado": req.estado
    },{headers});
  }
  asignarServicio(idServicio, idMandante){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/Mandante/PostAsignarMandante',{
      "idServicio": idServicio,
      "idMandante": idMandante
    },{headers});
  }
  getListaMandanteCantidadServicios(pagina, numeroRegistros){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/mandante/PostObtenerTodos',{
      "estado": "Activo",
      "pagina": pagina,
      "numeroRegistros": numeroRegistros
    },{headers});
  }
  getServiciosPlanesByIdMandante(id){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Servicio/getservicioplanes/'+id,{headers});
  }
  getServiciosByIdMandante(idMandante){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Servicio/getServiciosByIdMandante/'+idMandante,{headers});
  }
}
